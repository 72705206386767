@import "../../_globalColor";

.skills-main-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 3rem 0;
}

.skills-image-div {
  flex: 1;
}

.skills-text-div {
  flex: 1;
  padding: 1rem 2rem;
}

.skills-heading {
  font-size: 2.5rem;
  font-weight: 700;
  color: $titleColor;
}

.skills-text-subtitle {
  font-size: 1.5rem;
  color: $subTitle;
}

.dark-mode .skills-heading {
  color: $textColorDark;
}

.dark-mode .skills-text-subtitle {
  color: $textColorDark;
}

@media (max-width: 768px) {
  .skills-main-div {
    flex-direction: column;
  }

  .skills-image-div {
    margin-bottom: 2rem;
  }
}
