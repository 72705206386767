@import "../../_globalColor";

.service-card {
  display: flex;
  flex-direction: column;
  background-color: $lightBackground2;
  padding: 1.5rem;
  border-radius: 10px;
  border: 1px solid $lightBorder1;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}

.service-card-light {
  box-shadow: $lightBoxShadowDark 0px 10px 30px -15px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.service-card-dark {
  box-shadow: 0px 10px 30px -15px $darkBoxShadow;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.25s ease;
}

.service-detail {
  text-align: center;
}

.service-card-light:hover {
  box-shadow: $lightBoxShadowDark 0px 20px 30px -10px;
}

.service-card-dark:hover {
  box-shadow: 0px 20px 30px -10px $darkBoxShadow;
}

.service-image {
  position: relative;
  height: 250px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.services-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem 1rem;
}

.services-header {
  color: $titleColor;
  font-size: 32px; // Atualizado para ser consistente com outros blocos
  line-height: 40px; // Atualizado para ser consistente com outros blocos
  font-weight: 700;
  margin: 0 0 1.5rem 0;
  
}

.services-subheader {
  color: $subTitle; 
  font-size: 22px; // Ajustado para ser um pouco menor que o título
  line-height: 28px; // Ajustado para ser um pouco menor que o título
  font-weight: 500;
  margin: 0 0 2rem 0;
  text-align: center;
}

.service-desc {
  color: $cardSubtitle;
  font-size: 17px;
  line-height: 1.5rem;
}

.dark-mode .services-header {
  color: $textColorDark; // Garante que a cor do título seja visível no modo escuro
}

.dark-mode .services-subheader {
  color: $textColorDark; // Garante que a cor do subtítulo seja visível no modo escuro
}



